<app-top-header class="top-bar-container" [bgStyle]="'solid'"></app-top-header>

<div class="page-content" *ngIf="item">
  <div class="row justify-content-start align-items-start bg-white">
    <div class="col-auto">
      <div class="side-menu details-header py-2">
        <i
          (click)="goBack()"
          class="far fa-angle-left fa-lg cursor-pointer"
        ></i>

        <span class="font-weight-bold font-14 px-2 color-black font-16">
          {{ item?.itemInfo?.title | iknLocalized }}
        </span>
      </div>
    </div>
  </div>
  <div iknLoader [isActive]="!item" class="loader-container"></div>
  <section *ngIf="item" class="home-slide slide_sec position-relative">
    <div class="slide-blog p-0">
      <div class="row no-gutters">
        <div class="col-12">
          <!-- <div (click)="goBack()" class="cursor-pointer back">
            <i class="far fa-angle-left fa-2x"></i>
          </div> -->
          <owl-carousel-o
            [options]="topSlidersOptions"
            *ngIf="item?.itemInfo?.itemPics?.length > 0"
          >
            <ng-template
              carouselSlide
              *ngFor="let itemPic of item?.itemInfo.itemPics; let i = index"
            >
              <!-- <div
                (click)="showImages(item?.itemInfo?.itemPics, i)"
                [style.background-image]="
                  'url(' + itemPic.pic || '/assets/images/logo.png' + ')'
                "
                style="min-height: 300px; width: 100%"
                [style.backgroundSize]="'contain'"
                [style.backgroundRepeat]="'no-repeat'"
                [style.backgroundPosition]="'center center'"
              ></div> -->
              <img
                (click)="showImages(item?.itemInfo?.itemPics, i)"
                [src]="itemPic.pic || '/assets/images/logo.png'"
                class="d-block w-100 carousel-item object-fit-contain"
              />
            </ng-template>
          </owl-carousel-o>
          <div
            class="carousel-item"
            *ngIf="item?.itemInfo?.itemPics?.length == 0"
          >
            <img
              (click)="showImages(item?.itemInfo?.mainPic)"
              [src]="item?.itemInfo?.mainPic || 'assets/images/logo.png'"
              alt=""
              class="d-block w-100"
            />
          </div>
        </div>
      </div>
      <!-- row -->
    </div>
  </section>
  <div class="p-3 border-custom">
    <h5 class="item-title">
      {{ item?.itemInfo?.title | iknLocalized }}
    </h5>
    <p class="item-desc">
      {{ item?.itemInfo?.ingredients | iknLocalized }}
      {{ item?.itemInfo?.notes | iknLocalized }}
    </p>
    <!-- <h5 *ngIf="!(item?.itemInfo?.detailSections?.length > 0)" class="item-title-2" >{{'Price on selection'| translate}} </h5> -->
    <ng-container *ngIf="!checkOutOfStock()">
      <h5
        *ngIf="item?.itemInfo?.detailSections?.length > 0"
        class="item-title-2"
      >
        {{ "Price on selection" | translate }}
      </h5>
      <h5
        *ngIf="!(item?.itemInfo?.detailSections?.length > 0)"
        class="item-title-2 mb-0"
      >
        {{ item.itemInfo.price.toFixed(3) }}
        {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        <span
          *ngIf="
            item?.itemInfo?.priceBefore != null &&
            item?.itemInfo?.priceBefore > 0
          "
          class="line-through"
        >
          {{ item.itemInfo.priceBefore | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
      </h5>
    </ng-container>
    <ng-container *ngIf="checkOutOfStock()">
      <h5 class="item-title-2 card-price text-danger font-bold">
        {{ "Out of Stock" | translate }}
      </h5>
    </ng-container>
  </div>

  <div class="container border-custom">
    <div class="d-flex justify-content-between align-items-center py-3">
      <div>
        <h5 class="item-title-2">{{ "Quantity" | translate }}</h5>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span (click)="qty = qty > 1 ? qty - 1 : qty" class="value-item">
          <span class="fa fa-minus"></span>
        </span>
        <span class="value-item quantity">
          <span style="color: #000">{{ qty }}</span>
        </span>
        <span (click)="addQty()" class="value-item">
          <span class="fa fa-plus"></span>
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="item.itemInfo.setupTime" class="container border-custom">
    <div class="row justify-content-between align-items-center py-3">
      <div>
        <h5 class="item-title-2">{{ "Preparation Time" | translate }}</h5>
      </div>
      <div>
        {{ item.itemInfo.setupTime | iknMinutes }}
      </div>
    </div>
  </div>
  <div class="mt-2" *ngFor="let sec of item.itemInfo.detailSections">
    <div
      class="d-flex"
      [class]="{
        'flex-column': sec.type != 'SingleLine',
        'align-items-center': sec.type == 'SingleLine'
      }"
    >
      <h6 class="m-0 mx-1">{{ sec.title | iknLocalized }}:</h6>
      <div *ngIf="sec.type != 'Html'" class="overflow-hidden w-100">
        {{ sec.details | iknLocalized }}
      </div>
      <div *ngIf="sec.type == 'Html'">
        <div [innerHTML]="sec.details | iknLocalized | iknHtml"></div>
      </div>
    </div>
  </div>
  <div
    *ngIf="item.extras && item.extras.length"
    class="container py-3 border-custom"
  >
    <h5 class="item-title-2">{{ "Extras" | translate }}</h5>
    <div *ngFor="let ecat of item.extras">
      <div class="py-3">
        <div>
          <h5 class="item-title-2">
            {{ ecat.extraCategory.title | iknLocalized }}
          </h5>
        </div>
        <div>
          <span *ngIf="ecat.extraCategory.minQty > 0" class="font-12">
            {{ "Choose" | translate }} {{ ecat.extraCategory.minQty }}
          </span>
        </div>
      </div>
      <div
        *ngFor="let extra of ecat.extraItems"
        class="row justify-content-between align-items-center border-bottom-1 flex-nowrap"
      >
        <div>
          <div class="ex-inner">
            <img
              (click)="showImages(item?.itemInfo?.mainPic)"
              *ngIf="extra.mainPic"
              class="ex-img"
              [src]="extra.mainPic"
            />
            <div
              class="d-flex justify-content-between align-items-center flex-wrap"
            >
              <div>
                <span class="font-12 font-weight-600 color-black">
                  {{ extra.title | iknLocalized }}
                </span>
              </div>
              <div
                *ngIf="ecat.selected == extra.id || extra.qty > 0"
                class="px-3 d-flex justify-content-between align-items-center"
              >
                <ng-container *ngIf="!checkExtraOutOfStock(extra)">
                  <span (click)="removeExtraItem(extra)" class="value-item">
                    <span class="fa fa-minus"></span>
                  </span>
                  <span class="value-item quantity">
                    <span style="color: #000"> {{ extra.qty }}</span>
                  </span>
                  <span (click)="addExtraItem(extra, ecat)" class="value-item">
                    <span class="fa fa-plus"></span>
                  </span>
                </ng-container>
                <ng-container *ngIf="checkExtraOutOfStock(extra)">
                  <span class="text-danger">
                    {{ "Out of Stock" | translate }}
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div>
          <ng-container *ngIf="!checkExtraOutOfStock(extra)">
            <div
              (click)="changeCatSelectedItem(ecat, extra)"
              class="custom-radio custom-radio-item"
            >
              <input
                class="cursor-pointer"
                id="styled-radio-4{{ extra.id }}"
                type="radio"
                [checked]="ecat.selected == extra.id"
                [value]="extra.selected"
              />
              <label
                for="styled-radio-4{{ ecat.id }}"
                class="cursor-pointer radio-label-st"
              >
                <span *ngIf="extra?.price > 0">
                  {{ "(" }} {{ extra.price | iknCurrency }}
                  {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
                  {{ ")" }}
                </span>
              </label>
            </div>
            <!-- <span *ngIf="extra?.price > 0">
                               {{'('}} {{extra.price | iknCurrency}}
                               {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                                  {{')'}}
                            </span> -->
          </ng-container>
          <ng-container *ngIf="checkExtraOutOfStock(extra)">
            <span class="text-danger">
              {{ "Out of Stock" | translate }}
            </span>
          </ng-container>
        </div>

        <!-- <div class="flex-grow-1 px-2" *ngIf="!checkExtraOutOfStock(extra) && extra.isStocked">
                        {{'Stock Quantity'| translate}}    ({{extra.stockQty}})
                    </div>
                    <div>
                        <span class="text-danger out-stock-txt font-extraBold" *ngIf="checkExtraOutOfStock(extra)">
                            {{'Out of Stock'| translate}}
                        </span>
                        <span *ngIf="!checkExtraOutOfStock(extra)">
                            {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                            {{extra.price | iknCurrency}}
                        </span>

                    </div> -->
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-between align-items-center py-3">
      <div>
        <h5 class="item-title-2">{{ "Add a note" | translate }}</h5>
      </div>

      <div onclick="document.getElementById('notes').focus(); return false;">
        <span>
          <svg
            style="width: 20px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              class="note-path"
              d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="row justify-content-start align-items-start">
      <div class="textarea-wrapper mb-2 w-100">
        <textarea
          id="notes"
          [(ngModel)]="notes"
          class="autoExpand"
          rows="3"
          data-min-rows="3"
          placeholder="{{ 'Add a note' | translate }}"
        ></textarea>
      </div>
    </div>
  </div>

  <div
    style="background-color: #fff"
    class="page-btn f-booldest t-small"
    *ngIf="!isLoading"
    [@slideInUpOnEnter]
  >
    <div class="d-flex">
      <a
        class="btn btn-primary bg-primary pull-heading-right btn-cart f-booldest t-medium btn-date-time"
        style="flex: 2"
        (click)="!isOutOfStock && addToCart()"
        [class.disabled]="isOutOfStock"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div>
            {{ "ADD TO CART" | translate }}
          </div>
          <div class="mx-1"></div>
          <div>
            <div>
              {{ qty * calculatedPrice | iknCurrency }}
              {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
            </div>
          </div>
        </div>
      </a>
      <div class="mx-2"></div>
      <a
        class="btn btn-primary bg-primary pull-heading-right btn-cart f-booldest t-medium btn-date-time"
        style="flex: 1"
        (click)="addToCart(true)"
        [class.disabled]="isOutOfStock"
      >
        <div>
          {{ "CHECKOUT" | translate }}
        </div>
      </a>
    </div>
  </div>

  <div class="p-2">
    <!-- <div class="position-relative d-flex align-items-center justify-content-center">{{item?.itemInfo.ingredients | iknLocalized}}</div>

        <h6>{{item?.itemInfo?.notes | iknLocalized}}</h6> -->
    <!-- <div class="position-relative d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column align-items-center justify-content-center">
                <h6>{{'Quantity' | translate}}</h6>
                <div class="d-flex align-items-center justify-content-between border rounded" style='min-height: 1.5rem;'>
                    <div class="px-2" (click)="addQty()">
                        <h6 class="fa fa-plus m-0"></h6>
                    </div>
                    <div class="px-2 f-boldest">{{qty}}</div>
                    <div class="px-2" (click)="qty= qty >1 ? qty-1 : qty">
                        <h6 class="fa fa-minus m-0"></h6>
                    </div>
                </div>
            </div>
            <div class="position-absolute" style="left:0;right:0; top:2rem;pointer-events: none">
                <div class="d-flex align-items-center justify-content-end" style="pointer-events: none">
                    <div class="price-before"
                         *ngIf="item?.itemInfo?.priceBefore != null && item?.itemInfo?.priceBefore > 0">
                        {{item.itemInfo.priceBefore | iknCurrency}}
                        {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                    </div>
                    <div class="price color-primary">
                        {{item.itemInfo.price | iknCurrency}}
                        {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                    </div>
                </div>
            </div>
        </div> -->

    <!-- <div class="mt-2" *ngFor="let sec of item.itemInfo.detailSections">
            <div class="d-flex"
                 [class]="{'flex-column': sec.type != 'SingleLine', 'align-items-center': sec.type == 'SingleLine'}">
                <h6 class="m-0 mx-1">{{sec.title | iknLocalized}}: </h6>
                <div *ngIf="sec.type != 'Html'" class="overflow-hidden w-100">
                    {{sec.details | iknLocalized}}
                </div>
                <div *ngIf="sec.type == 'Html'">
                    <div [innerHTML]="sec.details | iknLocalized | iknHtml"></div>
                </div>
            </div>
        </div> -->
    <!-- <hr class="m-0 p-0 my-1"> -->
    <!-- <div *ngIf="item.extras && item.extras.length">
            
            <h6 class="text-center font-extraBold">{{'Extras' | translate}}</h6>

            <div *ngFor="let ecat of item.extras">

                <div class="d-flex justify-content-between align-items-center">
                    <h6 class="m-0 font-extraBold">{{ecat.extraCategory.title | iknLocalized}}</h6>
                    <h6 class="m-0 color-grey" *ngIf="ecat.extraCategory.minQty">
                        {{'Choose' | translate}} {{ecat.extraCategory.minQty}}
                    </h6>
                </div>
                <hr class="m-0 my-1">
                <div class="d-flex align-items-center justify-content-between mb-2"
                     *ngFor='let extra of ecat.extraItems'>
                    <div style="min-width: 45px;min-height: 45px; width:45px;height:45px;border-radius: 5px;">
                        <img [src]="extra.mainPic || item.itemInfo.mainPic" style="width:100%;height:100%;border-radius: 5px;">
                    </div>
                    <div class="d-flex align-items-center justify-content-between border rounded mx-1">
                        <div  (click)="addExtraItem(extra, ecat)"
                             style="width:2rem;height:1.5rem;line-height: 1.5rem;;text-align: center">
                            <h6 class="fa fa-plus m-0"></h6>
                        </div>
                        <h6 class=" m-0" style="width:2rem;height:1.5rem;line-height: 1.5rem;text-align: center;">
                            {{extra.qty}}
                        </h6>
                        <div  (click)="removeExtraItem(extra)"
                             style="width:2rem;height:1.5rem;line-height: 1.5rem;;text-align: center">
                            <h6 class="fa fa-minus  m-0"></h6>
                        </div>
                    </div>
                    <div class="flex-grow-1 px-2">
                        {{extra.title | iknLocalized}}
                    </div>

                    <div class="flex-grow-1 px-2" *ngIf="!checkExtraOutOfStock(extra) && extra.isStocked">
                        {{'Stock Quantity'| translate}}    ({{extra.stockQty}})
                    </div>
                    <div>
                        <span class="text-danger out-stock-txt font-extraBold" *ngIf="checkExtraOutOfStock(extra)">
                            {{'Out of Stock'| translate}}
                        </span>
                        <span *ngIf="!checkExtraOutOfStock(extra)">
                            {{truck?.defaultCountry?.currencyCode | iknLocalized}}
                            {{extra.price | iknCurrency}}
                        </span>

                    </div>

                </div>
            </div>
        </div> -->

    <!-- <div class="product-special-note font-extraBold mt-2">
            <h6 class='t-large t-boldest'>{{'Add a note' | translate}}</h6>
            <div class="textarea-wrapper">
                <textarea [(ngModel)]="notes" class='autoExpand' rows='1' data-min-rows='1'
                          placeholder="{{'Add a note'| translate}}"></textarea>
            </div>
        </div> -->
  </div>
</div>

<app-gallery-item #galleryItemComponent> </app-gallery-item>
<!--PIXEL META DATA-->
<meta property="og:title" content="{{ item?.itemInfo.title.en }}" />
<meta property="og:description" content="{{ item?.itemInfo.ingredients.en }}" />
<meta property="og:url" content="{{ pageUrl }}" />
<meta property="og:image" content="{{ item?.itemInfo.mainPic }}" />
<meta property="product:price:amount" content="{{ item?.itemInfo?.price }}" />
<meta property="product:price:currency" content="KWD" />
<meta property="product:retailer_item_id" content="{{ item?.itemInfo?.id }}" />
<meta property="product:availability" content="in stock" />
<!-- <app-footer></app-footer> -->
