import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { UtilsService } from "../utils.service";
import { Router } from "@angular/router";
import { ApiService } from "../api.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment.prod-default";
import { NotifyService } from "../notify.service";
import { slideInUpOnEnterAnimation } from "angular-animations";
import { TruckInfo, TruckSBOptionsWithDetails, TruckViewDto } from "../models";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  animations: [slideInUpOnEnterAnimation()],
})
export class RegisterComponent implements OnInit {
  submitForm: FormGroup;
  contactPhoneLengthError = false;

  submitted = false;
  isLoading: boolean = true;
  showPass: boolean = false;
  truck: TruckViewDto;
  //phone number component
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Kuwait, CountryISO.Qatar];
  phoneNumber: any;
  confirmPassword = "";
  data = {
    userName: "",
    password: "",
    profile: {
      phoneNumber: "",
      email: "",
      displayName: "",
    },
  };

  constructor(
    public utils: UtilsService,
    private router: Router,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private notify: NotifyService
  ) {}

  ngOnInit() {
    this.utils.fbTrack("Register");
    //$('.page-side').removeClass('sm-hidden');
    this.submitForm = this.formBuilder.group({
      name: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
    this.api.viewTruck().subscribe(
      (d) => {
        this.truck = d;

        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
    this.api.checkIfAuthenticated().subscribe((res) => {
      if (res && this.api.getAuthUser().userId != -1)
        this.router.navigate(["Home"]);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.submitForm.controls;
  }

  showPassword() {
    this.showPass = !this.showPass;
  }
  validatePhoneNumber() {
    if (this.phoneNumber && this.phoneNumber.countryCode == "KW") {
      if (
        !this.phoneNumber?.number ||
        this.phoneNumber?.number?.replace(/\s/g, "").length != 8 ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("0") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("1") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("3") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("7") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("8")
      ) {
        return true;
      }
      this.submitForm.controls["phoneNumber"].setErrors(null);
      return false;
    }
  }
  register() {
    this.validatePhoneNumber();

    this.submitted = true;

    // stop the process here if form is invalid
    if (this.submitForm.invalid) return;

    this.isLoading = true;

    this.data.profile.phoneNumber = this.phoneNumber.internationalNumber;
    this.api.register(this.data).subscribe(
      (res) => {
        this.isLoading = false;
        this.api.authUser(res);
        this.router.navigate(["/home"]);
      },
      (err) => {
        this.isLoading = false;
        this.phoneNumber = this.phoneNumber.internationalNumber;
        this.notify.sweet_error(err);
      }
    );
  }
}
