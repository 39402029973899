import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CartService } from "../cart.service";
import { UtilsService } from "../utils.service";
import { ToastrService } from "ngx-toastr";
import { Cart } from "../cart";
import { AreaComponent } from "../area/area.component";
import {
  Address,
  CountryListDto,
  governate,
  Area,
  AppEventType,
} from "../models";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import * as _ from "lodash";
import { Router, ActivatedRoute } from "@angular/router";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { TranslateService } from "@ngx-translate/core";
import { AddressPartComponent } from "../address-part/addres-part.component";

@Component({
  selector: "app-confirm-address",
  templateUrl: "./confirm-address.component.html",
  styleUrls: ["./confirm-address.component.scss"],
})
export class ConfirmAddressComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isLoading: boolean = false;
  isSaving: boolean = false;
  cart: Cart;
  type = "add";
  screenTitle = "Checkout";
  returnTo = "/my-addresses";
  locationReturnTo = "/addresses/new";
  address: Address = { type: "house", location: {} };
  addressId: number;
  locationSelected: boolean = false;
  @ViewChild("address") cmpAddress: AddressPartComponent;

  @ViewChild(AreaComponent) areaSelector: AreaComponent;
  constructor(
    private srvCart: CartService,
    public utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private trans: TranslateService
  ) {
    this.cart = srvCart.getCart();
    this.route.data.subscribe((d) => {
      this.type = d["type"] || "add";
      if (this.type == "add") {
        this.screenTitle = "New Address";
      } else if (this.type == "edit") {
        this.screenTitle == "Edit Address";
      } else {
        this.screenTitle = "Checkout";
      }
    });
    this.route.params.subscribe((d) => {
      this.returnTo = d["returnTo"] || "/my-addresses";
      this.addressId = d["addressId"];
      if (this.addressId)
        this.locationReturnTo = `/addresses/${this.addressId}/edit`;
      this.locationSelected = d["locationSet"] == "yes" ? true : false;
      if (this.locationSelected) {
        this.address.blockNo = d["blockNo"];
        this.address.street = d["street"];
        this.address.avenue = d["avenue"];
        this.address.location.lat = Number(d["lat"]);
        this.address.location.lng = Number(d["lng"]);
      }
    });
  }

  ngOnInit() {
    this.utils.fbTrack("ConfirmAddress");
  }
  ngOnDestroy() {}

  ngAfterViewInit() {
    this.isLoading = true;

    if (this.addressId)
      this.api.viewAddress(this.addressId).subscribe(
        (d) => {
          let address = d && d.address;
          if (this.locationSelected) {
            address.location.lat =
              this.address.location.lat ?? address.location.lat;
            address.location.lng =
              this.address.location.lng ?? address.location.lng;
            address.blockNo = this.address.blockNo ?? address.blockNo;
            address.avenu = this.address.avenue ?? address.avenue;
            address.street = this.address.street ?? address.street;
          }
          this.address = address;

          this.cmpAddress.setAddress(this.address, d && d.area);
          this.isLoading = false;
        },
        (d) => {
          this.isLoading = false;
        }
      );
  }
  confirmAddress() {
    this.api.trackEvent(AppEventType.ConfirmAddress);
    let saved = this.cmpAddress.saveAddress(this.address);

    if (!saved) return;
    this.isSaving = true;

    if (this.type == "add") {
      this.api.createAddress(this.address).subscribe(
        (d) => {
          this.isSaving = false;
          this.utils.goBack();
        },
        (err) => {
          this.isSaving = false;
        }
      );
    } else if (this.type == "edit") {
      this.api.updateAddress(this.addressId, this.address).subscribe(
        (d) => {
          this.isSaving = false;
          this.utils.goBack();
        },
        (err) => {
          this.isSaving = false;
        }
      );
    } else {
      //?
      this.isSaving = false;
    }
  }
}
