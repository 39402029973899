import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catering',
  templateUrl: './catering.component.html',
  styleUrls: ['./catering.component.css']
})
export class CateringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }
  download(){
    var fileUrl = "/assets/images/menu.pdf";
    window.open(fileUrl, '_blank');
  }

}
