<app-top-header class="top-bar-container" [bgStyle]="'solid'"></app-top-header>

<div class="row justify-content-start align-items-start pt-3 bg-gray-2">
  <div class="container">
    <div class="side-menu details-header py-2">
      <i (click)="goBack()" class="far fa-angle-left fa-lg cursor-pointer"></i>

      <span class="font-weight-bold px-2 color-black font-14">
        {{ "Order Details" | translate }}</span
      >
    </div>
  </div>
</div>
<h6
  class="font-extraBold text-center p-4"
  *ngIf="!isLoading && !cart?.cartItems?.length"
>
  {{ "No Items In Your Cart" | translate }}
</h6>
<div
  iknLoader
  [isActive]="isLoading"
  class="loader-container text-center"
></div>
<ng-container *ngIf="!isLoading && cart?.cartItems?.length">
  <div class="container py-2">
    <h5 class="item-title">
      <span class="font-14 text-span-black" *ngIf="!cart?.orderInfo.pickupTime">
        {{ "Choose Time - Date" | translate }}
      </span>
      <span class="font-14 text-span-black" *ngIf="cart?.orderInfo.pickupTime">
        {{ "Deliver Now" | translate }}
      </span>
    </h5>
    <div class="row justify-content-between align-items-center">
      <div>
        <span class="text-span-black" *ngIf="cart?.orderInfo.pickupTime">
          {{ cart?.orderInfo.pickupTime | iknDate : "day" }}
          {{ cart?.orderInfo.pickupTime | iknDate : "dm" }}
          {{ cart?.orderInfo.pickupTime | iknDate : "tf"
          }}<span class="text-span-black" *ngIf="cart?.orderInfo.pickupTimeTo">
            - {{ cart?.orderInfo.pickupTimeTo | iknDate : "tf" }}</span
          >
        </span>
      </div>
      <div>
        <span>
          <a
            *ngIf="
              cart.serviceType_ex &&
              cart.serviceType_ex != 'soonest_delivery' &&
              cart.serviceType_ex != 'pickup'
            "
            [routerLink]="['/time-date']"
            href=""
            class="text-danger font-14"
            >{{ "Edit" | translate }}</a
          >

          <a
            *ngIf="
              !(
                cart.serviceType_ex &&
                cart.serviceType_ex != 'soonest_delivery' &&
                cart.serviceType_ex != 'pickup'
              )
            "
            [routerLink]="['/service']"
            href=""
            class="text-danger font-14"
            >{{ "Edit" | translate }}</a
          >
        </span>
      </div>
    </div>
  </div>
  <div class="container mx-0 px-0 space-between"></div>
  <div class="py-2">
    <div class="container">
      <h5 class="item-title">
        <span class="color-black font-14">
          {{ "Order Items" | translate }}
        </span>
      </h5>
    </div>
    <ng-container
      *ngFor="let item of cart?.cartItems; let i = index; let last = last"
    >
      <div
        [@lightSpeedInOnEnter]
        class="container"
        [ngStyle]="
          i != cart?.cartItems?.length - 1
            ? { 'border-bottom': '2px solid #F0F0F0 ' }
            : { 'border-bottom': '0px solid #F0F0F0 ' }
        "
      >
        <div class="row justify-content-between align-items-center py-1">
          <div>
            <span class="font-14 text-span-black">
              {{ item.$item.title | iknLocalized }}
            </span>
          </div>
          <div>
            <h6>
              <span class="text-span-black font-14 font-weight-bold">
                {{ item.$total | iknCurrency }}
                {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
              </span>
            </h6>
          </div>
        </div>

        <ng-container *ngIf="item.extras && item.extras.length">
          <div
            *ngFor="let extra of item.extras"
            class="row justify-content-between align-items-center"
          >
            <div>
              <div>
                <span class="px-2 extra-item-span">{{
                  extra.$extraItem.title | iknLocalized
                }}</span>
              </div>
            </div>
            <div></div>
          </div>
        </ng-container>

        <div class="row justify-content-between align-items-center py-3">
          <div class="d-flex justify-content-between align-items-center">
            <span (click)="addLess(item)" class="value-item">
              <span class="fa fa-minus"></span>
            </span>

            <span class="value-item quantity">
              <span style="color: #000">{{ item.qty }}</span>
            </span>
            <span (click)="addMore(item)" class="value-item">
              <span class="fa fa-plus"></span>
            </span>
          </div>
          <div>
            <div class="d-flex align-items-center justify-content-between">
              <span
                ><a
                  [routerLink]="['/item', item.$item.id]"
                  class="edit text-secondary mx-2 text-span-gray font-14 font-bold"
                >
                  {{ "Edit" | translate }}</a
                ></span
              >

              <span
                (click)="removeItem(item)"
                class="edit text-danger font-14 font-bold"
              >
                {{ "Remove" | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="container mx-0 px-0 space-between"></div>

  <div class="container py-2">
    <h5 class="item-title pb-2">
      <span class="color-black font-14">{{ "PROMO CODE" | translate }}</span>
    </h5>
    <div class="justify-content-between align-items-center">
      <section class="search-sec w-100">
        <div class="row">
          <div class="col-12 px-0">
            <div class="form-group m-0">
              <div class="input-group input-group-abs">
                <div
                  style="height: 50px; line-height: 1.5"
                  class="input-group-prepend"
                >
                  <!-- <i class="icon-search"></i> -->
                  <svg
                    style="width: 25px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      style="fill: #d0d0d0"
                      d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  [readonly]="!!cart.orderInfo?.promoCode"
                  [(ngModel)]="promoCode"
                  class="form-control rounded-0 search-location w-100 px-5 input-promo"
                  placeholder="{{ 'Enter Promo Code' | translate }}"
                />
              </div>

              <a
                class="color-primary promocode-confirm"
                (click)="!isValidatingPromoCode && redeemOrDeletePromoCode()"
              >
                <div class="lds-ellipsis" *ngIf="isValidatingPromoCode">
                  <div></div>
                  <div></div>
                </div>
                <span *ngIf="!isValidatingPromoCode">
                  {{
                    cart.orderInfo.promoCode ? "REMOVE" : ("APPLY" | translate)
                  }}
                </span>
              </a>
              <div class="font-bold py-2" *ngIf="cart.orderInfo.promoCode">
                <h6>
                  <strong class="color-primary"
                    >{{ "Discount" | translate }}:
                  </strong>
                  {{ cart.$discount | iknCurrency }}
                  {{ "KWD" | translate }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="container mx-0 px-0 space-between"></div>

  <div class="container py-2">
    <div class="row justify-content-between align-items-center mb-2">
      <div>
        <span class="font-12 text-span-black">
          {{ "Sub Total" | translate }}
        </span>
      </div>
      <div>
        <span class="font-14 text-span-black">
          {{ cart.$subTotal | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
      </div>
    </div>
    <div
      *ngIf="cart.$collectorCharge"
      class="row justify-content-between align-items-center mb-2"
    >
      <div>
        <span class="font-12 text-span-black">
          {{ "Collector Fees" | translate }}
        </span>
      </div>
      <div>
        <span class="font-14 text-span-black">
          {{ cart.$deliveryCharge | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
      </div>
    </div>

    <div class="row justify-content-between align-items-center mb-2">
      <div>
        <span class="font-12 text-span-black">
          {{ "Delivery" | translate }}
        </span>
      </div>
      <div>
        <span class="font-14 text-span-black">
          {{ cart.$deliveryCharge | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
      </div>
    </div>
    <div
      *ngIf="cart.$discount"
      class="row justify-content-between align-items-center mb-2"
    >
      <div>
        <span class="font-12 text-span-black">
          {{ "Discount" | translate }}
        </span>
      </div>
      <div>
        <span class="font-14 text-span-black">
          {{ cart.$discount | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </span>
      </div>
    </div>
    <div class="row justify-content-between align-items-center mb-2">
      <div>
        <h6 class="text-span-black font-14 font-bold">
          {{ "Total" | translate }}
        </h6>
      </div>
      <div>
        <h6 class="text-span-black font-14 font-bold">
          {{ cart.$total | iknCurrency }}
          {{ truck?.defaultCountry?.currencyCode | iknLocalized }}
        </h6>
      </div>
    </div>

    <div class="row justify-content-between align-items-center py-3">
      <div class="cart-share f-bold text-center m-auto">
        <div
          class="d-inline-flex align-items-center justify-content-center btn btn-outline-secondary px-2 py-2 border-radius-10"
        >
          <i
            shareButton="copy"
            class="far fa-link"
            [url]="generateShareUrl()"
            (click)="copyLink()"
          ></i>
          <div class="mx-2 color-primary font-12">
            {{ "Share if you care" | translate }}
          </div>
        </div>
      </div>
      <div class="cart-share f-bold text-center m-auto">
        <div
          class="d-inline-flex align-items-center justify-content-center btn btn-outline-secondary px-2 py-2 border-radius-10"
        >
          <i
            shareButton="whatsapp"
            class="fab fa-whatsapp"
            [url]="generateShareUrl()"
          ></i>
          <div class="mx-2 color-primary font-12">
            {{ "Share if you care" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center py-2">
      <div class="col-auto">
        <div class="f-boldest t-large" [@slideInUpOnEnter]>
          <a
            [routerLink]="['/']"
            class="btn btn-cart page-btn-checkout d-block px-2 f-boldest min-140"
            >{{
              "Continue
            Shopping" | translate
            }}</a
          >
        </div>
      </div>
      <div class="col-auto">
        <div
          class="f-boldest t-large"
          *ngIf="cart?.cartItems?.length"
          [@slideInUpOnEnter]
        >
          <a
            (click)="submitCart()"
            class="btn btn-cart page-btn-checkout d-block px-2 f-boldest d-block btn-min-width min-140"
            >{{ "Checkout" | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
