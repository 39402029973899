import { TruckBranch } from "./../models";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ApiResult,
  Area,
  governate,
  LocalizedField,
  TruckInfo,
} from "../models";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";
import { CartService } from "../cart.service";
import { Cart } from "../cart";
import * as _ from "lodash";
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInUpOnEnterAnimation,
} from "angular-animations";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { data } from "jquery";
import { environment } from "src/environments/environment";
import { CountrySelectorComponent } from "../country-selector/country-selector.component";

@Component({
  selector: "app-service-area",
  templateUrl: "./service-area.component.html",
  styleUrls: ["./service-area.component.scss"],
  animations: [slideInUpOnEnterAnimation({ delay: 1000 })],
})
export class ServiceAreaComponent implements OnInit {
  truck: TruckInfo;
  cart: Cart;
  selectedService: string;
  services = [];

  isLoading: boolean = false;
  areas = [];
  branches = [];
  governates: governate[] = [];
  defaultCountryGovernates: governate[] = [];

  selectedArea: Area;
  selectedBranch: TruckBranch;
  governate: governate;
  searchText: string;
  screenTitle = "Choose Service and Area";
  forCatering = false;
  canSelectService = true;
  returnTo: string;
  _oldArea: number;
  allowShipping: boolean = false;
  country: any;
  results = [];
  showArea: boolean = true;
  isLoadingCities: boolean = false;
  selectMode: string = "";
  @ViewChild(CountrySelectorComponent, { static: true })
  countrySelector: CountrySelectorComponent;
  constructor(
    private trans: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private srvCart: CartService,
    public utils: UtilsService
  ) {
    this.cart = srvCart.getCart();
    this.country = this.cart.orderInfo.pickupAddress.countryName;
    this.route.data.subscribe((d) => {
      if (d["forCatering"]) {
        this.screenTitle = "Catering";
        this.canSelectService = false;
        this.forCatering = true;
        this.selectedService = "catering";
        this.selectMode = "areas";
      }
    });
    this.route.params.subscribe((d) => {
      if (d["returnTo"]) {
        this.returnTo = d["returnTo"];
      }
    });
    api.viewTruck().subscribe((t) => {
      this.truck = t.truck;
      this.cart = srvCart.getCart();
      this.selectedService = this.cart.serviceType_ex;
      if (this.cart.serviceType_ex == "catering")
        this.selectedService = "soonest_delivery";
      this.allowShipping = this.truck.allowShippingOutsideCountry;
      if (_.includes(this.truck.services, "delivery")) {
        this.services.push("soonest_delivery");
        this.services.push("delivery");
        //we should add the as soon as as possible special method
      }
      if (_.includes(this.truck.services, "pickup")) {
        this.services.push("pickup");
      }
      if (this.cart.orderInfo.pickupAddress.countryId)
        this.onCountryChange(this.cart.orderInfo.pickupAddress.countryId);
      else this.selectMode = "areas";
      // if (_.includes(this.truck.services, 'catering')) {
      //   this.services.push('catering');
      // }
      if (!this.selectedService) this.selectedService = this.services[0];

      if (!this.cart.serviceType_ex) {
        this.setService(this.selectedService, true);
      }
    });

    let self = this;
    var areaId = srvCart.getCart().orderInfo.pickupAddress.areaId;

    api.viewSBOptionsDetails().subscribe((t) => {
      api.getGovernate().subscribe((data) => {
        let deleted = [21, 44, 45, 46, 48, 53, 55, 65, 69, 185];
        _.each(data.items, (gov) => {
          gov.areas = _.filter(gov.areas, (a) =>
            _.some(t.areas, function (ta) {
              if (areaId == ta.area.id && a.id == ta.area.id) {
                self.selectedArea = a;
                a.deliveryCharge = ta.deliveryCharge;
              }
              return (
                !deleted.includes(a.id) &&
                ta.area.id == a.id &&
                ta.area.entityState == "Active"
              );
            })
          );
        });
        this.defaultCountryGovernates = data.items;
      });

      if (!t.hasMultipleBranches) {
        api.ViewTruckStatus().subscribe((st) => {
          this.branches = [
            {
              id: -1,
              name: {
                ar: "الفرع الرئيسي",
                en: "Main Branch",
              },
              area: {
                id: st.lastKnownLocation.areaId,
              },
              location: {
                location: st.lastKnownLocation.location,
                address: st.lastKnownLocation.address,
                contactPhone: t.supportPhone,
              },
              status: st.status,
            },
          ];
        });
      } else {
        api.viewTruckBranches(null).subscribe((d) => {
          this.branches = d && d.items;
        });
      }
    });
  }

  ngOnInit() {
    if (this.cart.serviceType_ex) {
      this.setService(this.cart.serviceType_ex);
    }
  }
  setService(service, isDefault: boolean = false) {
    let oldService = this.selectedService;
    //TODO: As Soon As Possible Service

    if (service == "soonest_delivery") {
      this.selectedService = service;
      this.srvCart.setServiceType("delivery", "soonest_delivery");
      this.cart.orderInfo.pickupTime = null;
      this.cart.orderInfo.pickupTimeTo = null;
      this.api.getDeliveryTimes(new Date(), "delivery").subscribe((t) => {
        this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
        if (t.asSoonAsFrom != t.asSoonAsTo)
          this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
      });
    } else {
      this.srvCart.setServiceType(service);
      this.selectedService = service;
      if (!this.forCatering && service == "pickup") {
        this.selectMode = "branches";
        this.api.getDeliveryTimes(new Date(), "pickup").subscribe((t) => {
          this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
          if (t.asSoonAsFrom != t.asSoonAsTo)
            this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
        });
      }
      //select time
      if (isDefault) {
        return;
      }
      if (
        service != "pickup" &&
        (this.selectedService != oldService || !this.cart.orderInfo.pickupTime)
      ) {
        this.router.navigate(["time-date"]);
      }
    }
    if (this.selectedService != oldService) {
      this.utils.toaster.success(
        this.trans.instant("Service type has been set") +
          " " +
          this.trans.instant("srv_" + this.selectedService)
      );
    }
  }
  saveArea(area: Area = null) {
    if (
      this.srvCart.getServiceType() == "catering" &&
      !this.forCatering &&
      this.cart.cartItems.length
    ) {
      this.utils
        .confirm(
          "Are you sure?",
          this.trans.instant("You'll lose items in your cart")
        )
        .then((d) => {
          if (!d.dismiss) this.saveConfirmedArea(area, true);
        });
    } else {
      this.saveConfirmedArea(area);
    }
  }
  saveConfirmedArea(area: Area = null, emptyCart = false) {
    this.selectedArea = area || this.selectedArea;
    this.srvCart.setArea(this.selectedArea);
    if (emptyCart) {
      this.cart.cartItems = [];
      this.srvCart.saveCart();
    }
    this.setService(this.selectedService);
    if (
      this.selectedService != "soonest_delivery" &&
      this.selectedService != "pickup"
    )
      if (!this.cart.orderInfo.pickupTime && !this.forCatering) {
        this.router.navigate(
          [
            "time-date",
            {
              continue: this.returnTo,
            },
          ],
          {
            replaceUrl: true,
            skipLocationChange: true,
          }
        );
        return;
      }
    if (this.selectedArea && this._oldArea != this.selectedArea.id) {
      //what to do when area changes?!
      //here we must re-init the truck
      this.api.initTruckInfo(this.selectedArea.id);
    }
    this.utils.goBack();
  }
  openCountry() {
    this.countrySelector.selectCountry(() => {
      this.country = this.cart.orderInfo.pickupAddress.countryName;
    });
  }
  saveBranch(branch = null) {
    this.selectedBranch = branch;
    //set area
    this.srvCart.setArea(branch.area);
    if (this.selectedService == "pickup") {
      this.setService(this.selectedService);
      this.srvCart.setBranch(branch);
      if (!this.cart.orderInfo.pickupTime || this.forCatering) {
        this.router.navigate(
          [
            "time-date",
            {
              continue: this.returnTo,
            },
          ],
          {
            replaceUrl: true,
            skipLocationChange: true,
          }
        );
        return;
      }
      this.utils.goBack();
    }
  }
  onGovernate(governate: governate) {
    this.governate = governate;
    governate.flag = !governate.flag;
    if (
      this.country &&
      this.cart.orderInfo.pickupAddress.countryId &&
      this.cart.orderInfo.pickupAddress.countryId !=
        this.truck.defaultCountry.id
    ) {
      this.srvCart.removeArea();
      this.setService(this.selectedService);
      this.srvCart.setGovernate(governate);
      //what to do when area changes?!
      //here we must re-init the truck
      this.api.initTruckInfo();
      this.utils.goBack();
    }
  }
  onCountryChange(countryId) {
    this.country = this.cart.orderInfo.pickupAddress.countryName;
    if (!this.forCatering && countryId != this.truck.defaultCountry.id) {
      this.showArea = false;
      this.selectMode = "city";
      if (this.selectedService == "pickup") {
        this.utils.toaster.warning(
          "Only delivery is supported when shipping abroad"
        );
        this.selectedService = "delivery";
      }
      this.isLoadingCities = true;
      this.api.getGovernate(countryId).subscribe(
        (g) => {
          this.governates = g && g.items;
          this.isLoadingCities = false;
        },
        (d) => {
          this.isLoadingCities = false;
        }
      );
    } else {
      this.showArea = true;
      if (!this.forCatering && this.selectedService == "pickup")
        this.selectMode = "branches";
      else this.selectMode = "areas";
    }

    //open another popup auto
    if (!this.showArea) {
      if (this.country != this.cart.orderInfo.pickupAddress.countryName)
        this.governate = undefined;
    }
  }
}
