import { Injectable, ViewRef, EventEmitter } from "@angular/core";
import * as _ from "lodash";
import { Cart } from "./cart";
import {
  CartItem,
  Address,
  round,
  MenuItem,
  CartExtraItem,
  TruckMenu,
  TruckBranch,
  MenuExtraItem,
  ExtraCategoryInfo,
  MenuItemInfo,
  MenuExtraCategory,
} from "./models";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "./utils.service";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class CartService {
  private self = this;
  public expectedDeliveryTime: Date = undefined;
  public expectedDeliveryTimeTo: Date = undefined;
  public deliveryTimeType: string = "";
  private cart: Cart;

  constructor(
    private utils: UtilsService,
    private toaster: ToastrService,
    private trans: TranslateService
  ) {
    this.cart = new Cart();

    let storedCart = localStorage.getItem("user_cart");
    if (storedCart == "undefined") storedCart = "null";
    let savedCart = storedCart ? (JSON.parse(storedCart) as Cart) : null;

    if (!savedCart) this.emptyCart();
    else {
      this.cart = _.assign(new Cart(), savedCart);
      this.cart.cartItems = _.map(savedCart.cartItems, (ic) => {
        let item = new CartItem();
        _.assignIn(item, ic);
        item.extras = _.map(ic.extras, (ei) => {
          let extra = new CartExtraItem();
          _.assignIn(extra, ei);
          return extra;
        });
        return item;
      });
    }
  }
  saveCart() {
    if (this.cart) {
      let nc = _.assignIn({}, this.cart) as any;
      if (nc.$truck) {
        nc.$truck = undefined;
      }
      if (nc.orderInfo && nc.orderInfo.$truck) nc.orderInfo.$truck = undefined;

      localStorage.setItem("user_cart", JSON.stringify(nc));
    }
  }
  getServiceType() {
    return this.cart.orderInfo.serviceType;
  }
  setTruck(truck) {
    this.cart.orderInfo.$truck = truck;
    this.cart.$truck = truck;
    //this.cart.orderInfo.serviceType = serviceType;
    if (truck) {
      let paymentMethod = _.first(truck.paymentMethodsLookups) as any;
      if (paymentMethod) paymentMethod = paymentMethod.paymentMethod;
      if (
        paymentMethod == "cashOnDelivery" &&
        truck.paymentMethodsLookups.length > 1
      )
        paymentMethod = truck.paymentMethodsLookups[1].paymentMethod;
      this.cart.orderInfo.paymentMethod = paymentMethod;
    }

    this.saveCart();
  }
  updateCart(menu: TruckMenu) {
    _.each(this.cart.cartItems, (ci) => {
      let i = _.find(menu.allItems, (mi) => mi.itemInfo.id == ci.$item.id);
      if (i) {
        ci.$item = i.itemInfo;
        ci.unitPrice = i.itemInfo.price;
      }
    });
    this.saveCart();
  }
  setShippingCharge(charge) {
    this.cart.shippingCharge = charge;
  }
  setPromoCode(promo, discount) {
    this.cart.orderInfo.promoCode = promo;
    this.cart.$discount = discount;

    this.saveCart();
  }
  setAddress(add) {
    this.cart.orderInfo.pickupAddress = _.extend({}, add) as Address;
    this.saveCart();
  }
  setPaymentMethod(method) {
    this.cart.orderInfo.paymentMethod = method;
    this.saveCart();
  }
  setServiceType(serviceType, serviceTypeEx = serviceType) {
    this.cart.orderInfo.serviceType = serviceType;
    this.cart.serviceType_ex = serviceTypeEx;
    this.deliveryTimeType = "";
    this.cart.orderInfo.truckDeliveryTimeId = undefined;
    this.saveCart();
  }

  getUnsupportedItems(serviceType) {
    return _.filter(
      this.cart.cartItems,
      (i) => !_.find(i.$services, (s) => s == serviceType)
    );
  }

  isEmpty() {
    return this.cart.cartItems.length == 0;
  }
  isOutOfStock(item: MenuItem) {
    let isExtraOutOfStock = false;
    if (item.extras)
      //if any required extra category is out of stock, the item is out of stock
      isExtraOutOfStock =
        item.extras &&
        item.extras.length &&
        _.some(item.extras, (c) => this.isExtraCategoryOutOfStock(c));

    return (
      item.itemInfo.isStocked &&
      (item.itemInfo.stockQty == 0 || isExtraOutOfStock)
    );
  }
  isExtraCategoryOutOfStock(extra: MenuExtraCategory) {
    return (
      extra.extraCategory.minQty > 0 &&
      _.every(
        extra.extraItems,
        (e) => e.isStocked && !e.saleOutStocked && e.stockQty == 0
      )
    );
  }
  canAddItem(serviceType, truck, item) {
    if (this.isEmpty()) return true;
    if (this.cart.orderInfo.$truck.id != truck.id) return false;
    if (this.cart.orderInfo.serviceType != serviceType) return false;
    if (
      !_.find(
        item.itemInfo.services,
        (d) => d == this.cart.orderInfo.serviceType
      )
    )
      return false;

    return true;
  }
  removeItem(itemId) {
    let item = _.find(this.cart.cartItems, (d) => d.itemId === itemId);
    if (item) {
      _.remove(this.cart.cartItems, item);
    }
    this.saveCart();
  }
  removeItemAt(index) {
    let item = this.cart.cartItems[index];
    if (item) {
      _.remove(this.cart.cartItems, item);
    }
    this.saveCart();
  }
  removeCartItem(ci: CartItem) {
    _.remove(this.cart.cartItems, ci);
    this.saveCart();
  }
  addItem(ci: CartItem) {
    let stored_item = _.find(this.cart.cartItems, ci);
    if (stored_item) {
      stored_item.qty += ci.qty;
      return;
    }
    this.cart.cartItems.push(ci);
    this.saveCart();
  }
  round(num) {
    return Math.round(num * 1000 + Number.EPSILON) / 1000;
  }
  updateQty(itemid, newQty) {
    let ic = _.find(this.cart.cartItems, (c) => c.itemId == itemid);
    let oldQty = ic.qty;
    ic.qty = newQty;
    this.saveCart();
  }
  updateQtyAt(index, newQty) {
    let ic = this.cart.cartItems[index];
    let oldQty = ic.qty;
    ic.qty = newQty;
    this.saveCart();
  }
  subTotal() {}
  emptyCart(keepArea: boolean = false) {
    let truck = this.cart && this.cart.$truck;
    let address = this.cart.orderInfo.pickupAddress;
    let branch = this.cart && this.cart.$branch;
    let dataToSave = {
      membershipNo: this.cart.orderInfo.membershipNo,
      civilId: this.cart.orderInfo.civilId,
      fileNumber: this.cart.orderInfo.fileNumber,
    };
    this.cart = new Cart();
    this.setTruck(truck);
    this.setBranch(branch);
    localStorage.setItem("user_cart", undefined);

    this.cart.orderInfo.pickupAddress = address;
    this.cart.orderInfo.membershipNo = dataToSave.membershipNo;
    this.cart.orderInfo.civilId = dataToSave.civilId;
    this.cart.orderInfo.fileNumber = dataToSave.fileNumber;
    if (!keepArea) {
      this.cart.orderInfo.pickupAddress.areaId = undefined;
      this.cart.orderInfo.pickupAddress.area = undefined;
      this.cart.orderInfo.pickupAddress.areaName = undefined;
    }
    this.saveCart();
  }
  total() {}

  canCheckout() {}

  checkOut(paymentMethod) {}

  getCart(): Cart {
    return this.cart;
  }
  removeArea() {
    this.cart.orderInfo.pickupAddress.areaId = null;
    this.cart.orderInfo.pickupAddress.areaName = null;
    this.saveCart();
  }
  setArea(area) {
    this.cart.orderInfo.pickupAddress.areaId = area.id;
    this.cart.orderInfo.pickupAddress.areaName = area.name;
    this.saveCart();
  }
  setBranch(branch: TruckBranch) {
    if (branch) {
      this.cart.orderInfo.branchId =
        (branch && branch.id > 0 ? branch.id : undefined) || undefined;
      this.cart.$branch = branch;
    } else {
      this.cart.orderInfo.branchId = null;
      this.cart.$branch = null;
    }
    this.saveCart();
  }

  setSubstitution(substitution) {
    this.cart.orderInfo.substitution = substitution;
    this.saveCart();
  }

  setCountry(country) {
    this.cart.orderInfo.pickupAddress.countryId = country.id;
    this.cart.orderInfo.pickupAddress.countryName = country.name;
    this.cart.orderInfo.pickupAddress.countryCode = country.code2;
    this.cart.orderInfo.pickupAddress.governateId = undefined;
    this.cart.orderInfo.pickupAddress.governateName = undefined;
    this.cart.orderInfo.pickupAddress.areaId = undefined;
    this.cart.orderInfo.pickupAddress.areaName = undefined;
    this.saveCart();
  }

  setGovernate(city) {
    this.cart.orderInfo.pickupAddress.governateId = city.id;
    this.cart.orderInfo.pickupAddress.governateName = city.name;
    this.saveCart();
  }

  validateItemToAdd(
    item: MenuItem,
    oldQty: number,
    qty: number,
    subTotal,
    checkExtras: boolean = false,
    confirmed: boolean = false
  ): boolean {
    if (this.utils.getTruckState(this.getCart().$truck) == "busy") {
      this.utils.warn(this.trans.instant("WE_ARE_BUSY"), "sorry");
      return false;
    }
    if (item?.qty + qty > item?.itemInfo.stockQty && item.itemInfo.isStocked) {
      this.utils.warn(this.trans.instant("EXCEEDQTY"), "sorry");
      return false;
    }

    if (checkExtras) {
      // let reqEC = _.find(item.extras, ec=> ec.extraItems.some(ei => !(ei.isStocked && ei.stockQty == 0 && !ei.saleOutStocked)) ?
      // (ec.extraCategory.minQty || 0) * (oldQty + qty) > _.sumBy(ec.extraItems, ei=>ei.qty) : false);
      let reqEC = _.find(
        item.extras,
        (ec) =>
          (ec.extraCategory.minQty || 0) * (oldQty + qty) >
          _.sumBy(ec.extraItems, (ei) => ei.qty)
      );
      if (reqEC) {
        this.trans
          .get("MIN_Qty_EXTRA", {
            ec: {
              minQty: (reqEC.extraCategory.minQty || 0) * (oldQty + qty),
              title: reqEC.extraCategory.title,
            },
          })
          .subscribe((msg) => {
            this.toaster.error(msg);
          });

        return false;
      }

      reqEC = _.find(
        item.extras,
        (ec) =>
          (ec.extraCategory.maxQty || 1) * (oldQty + qty) <
          _.sumBy(ec.extraItems, (ei) => ei.qty)
      );
      if (reqEC) {
        this.trans
          .get("MAX_Qty", {
            item: {
              maxQty: (reqEC.extraCategory.maxQty || 1) * (oldQty + qty),
              title: reqEC.extraCategory.title,
            },
          })
          .subscribe((msg) => {
            this.toaster.error(msg);
          });

        return false;
      }
    }

    if (item.itemInfo.maxQty && oldQty + qty > item.itemInfo.maxQty) {
      this.trans.get("MAX_Qty", { item: item.itemInfo }).subscribe((msg) => {
        this.toaster.error(msg);
      });
      return false;
    }
    if (
      this.cart.$truck.maxCartItemsCount > 0 &&
      qty + this.cart.$totalItemsQty > this.cart.$truck.maxCartItemsCount
    ) {
      //this.utils.warn(
      this.trans
        .get("MAX_NO_OF_ITEMS", {
          maxItemsCount: this.cart.$truck.maxCartItemsCount,
        })
        .subscribe((msg) => {
          this.toaster.error(msg);
        });
      //this.translate.instant('Please, Check the max quantity of all items')
      //,'Warning');
      return false;
    }

    if (
      subTotal &&
      this.cart.$truck.maxOrderCost > 0 &&
      subTotal + this.cart.$subTotal > this.cart.$truck.maxOrderCost
    ) {
      //this.utils.warn(
      this.trans
        .get("MAX_COST", { cost: this.cart.$truck.maxOrderCost })
        .subscribe((msg) => {
          this.toaster.error(msg);
        });
      //this.translate.instant('Please, Check the max quantity of all items')
      //,'Warning');
      return false;
    }

    if (!confirmed && this.utils.askForMembershipModal.askForMembership) {
      this.utils.askForMembershipModal.showModal(() => {
        //Do Nothing?
      });
      return false;
    }
    return true;
  }
  validateCart(): boolean {
    let itemReachedMaxQty = _.find(this.cart.cartItems, (c) =>
      this.cart.checkMaxQty(c)
    );
    if (itemReachedMaxQty) {
      this.utils.warn(
        this.trans.instant("MAX_Qty", { item: itemReachedMaxQty.$item })
      );
      return false;
    }

    if (
      this.cart.$truck.maxCartItemsCount > 0 &&
      this.cart.$totalItemsQty > this.cart.$truck.maxCartItemsCount
    ) {
      this.utils.warn(
        this.trans.instant("MAX_NO_OF_ITEMS", {
          maxItemsCount: this.cart.$truck.maxCartItemsCount,
        })
      );
      return false;
    }
    if (
      this.cart.$truck.maxOrderCost > 0 &&
      this.cart.$subTotal > this.cart.$truck.maxOrderCost
    ) {
      this.utils.warn(
        this.trans.instant("MAX_COST", { cost: this.cart.$truck.maxOrderCost })
      );
      return false;
    }

    if (
      this.cart.$truck.minOrderCost &&
      this.cart.$subTotal < this.cart.$truck.minOrderCost
    ) {
      this.utils.warn(
        this.trans.instant("min_order_warning", this.cart.$truck)
      );
      return false;
    }

    // Validate Extras
    let that = this;
    let validateExtras = true;
    _.each(this.cart.cartItems, function (item) {
      let reqEC = _.find(item.extras, function (ei) {
        let foundEc = that.getExtraCategory(item.$item.id, ei.$extraItem.catId);
        if (!foundEc) return;
        let totalExtrasQty = _.sumBy(
          item.extras,
          (ex) =>
            ex.$extraItem.catId == ei.$extraItem.catId && ex.$extraItem.qty
        );
        let minExtraCatQty = (foundEc.extraCategory.minQty || 0) * item.qty;
        let maxExtraCatQty = (foundEc.extraCategory.maxQty || 1) * item.qty;
        if (minExtraCatQty > totalExtrasQty) {
          that.trans
            .get("MIN_Qty_EXTRA", {
              ec: {
                minQty: minExtraCatQty,
                title: foundEc.extraCategory.title,
              },
            })
            .subscribe((msg) => {
              that.toaster.error(msg);
            });
          return true;
        }
        if (maxExtraCatQty < totalExtrasQty) {
          that.trans
            .get("MAX_Qty", {
              item: {
                maxQty: maxExtraCatQty,
                title: foundEc.extraCategory.title,
              },
            })
            .subscribe((msg) => {
              that.toaster.error(msg);
            });
          return true;
        }
      });
      if (reqEC) {
        validateExtras = false;
        return false;
      }
    });

    if (!validateExtras) return false;
    return true;
  }

  validateExtraItemToAdd(
    extra: MenuExtraItem,
    oldQty: number,
    qty: number,
    totalExtrasQty: number,
    itemQty: number,
    extraCategory: ExtraCategoryInfo
  ): boolean {
    if (extra.maxQty && oldQty + qty > extra.maxQty * itemQty) {
      // check extra maxqty also itself
      this.trans
        .get("MAX_Qty", {
          item: { maxQty: extra.maxQty * itemQty, title: extra.title },
        })
        .subscribe((msg) => {
          this.toaster.error(msg);
        });
      return false;
    }

    if (extra?.qty + qty > extra?.stockQty && extra.isStocked) {
      this.utils.warn(this.trans.instant("EXCEEDQTY"), "sorry");
      return false;
    }

    let ecMaxQty =
      (extraCategory.maxQty && extraCategory.maxQty * itemQty) || itemQty;
    if (totalExtrasQty > ecMaxQty) {
      this.trans
        .get("MAX_Qty", {
          item: { maxQty: ecMaxQty, title: extraCategory.title },
        })
        .subscribe((msg) => {
          this.toaster.error(msg);
        });
      return false;
    }

    if (
      extra.isStocked &&
      !extra.saleOutStocked &&
      extra.stockQty < oldQty + qty
    ) {
      this.trans.get("EXTRA_NOT_AVAILABLE", extra).subscribe((msg) => {
        this.toaster.error(msg);
      });
      return false;
    }
    // if(totalExtrasQty > itemQty){
    //   this.trans.get('EXCEED_ITEM_CART').subscribe(msg=>{
    //     this.toaster.error(msg);
    //   });
    //   return false;
    // }
    return true;
  }

  getExtraCategory(itemId, catItemId) {
    let mi = _.find(
      this.cart.$truck.menu.allItems,
      (c) => c.itemInfo.id == itemId
    );
    return _.find(mi.extras, (ec) => ec.extraCategory.id == catItemId);
  }
}
