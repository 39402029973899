import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { isArray } from "lodash";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap";
import { OwlOptions } from "ngx-owl-carousel-o";
@Component({
  selector: "app-gallery-item",
  templateUrl: "./gallery-item.component.html",
  styleUrls: ["./gallery-item.component.scss"],
})
export class GalleryItemComponent {
  @ViewChild("template") template: TemplateRef<any>;
  modalRef: BsModalRef;

  topSlidersOptions: OwlOptions;
  images: any = [];
  constructor(
    private trans: TranslateService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {}
  openModal(images, index) {
    index = index ? index : 0;
    this.topSlidersOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      autoplay: true,
      startPosition: index,
      dots: true,
      navSpeed: 300,
      rtl: this.trans.currentLang == "ar" ? true : false,
      navText: [" "],
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        740: {
          items: 1,
        },
        940: {
          items: 1,
        },
      },
      nav: false,
    };
    this.images = images;
    this.modalRef = this.modalService.show(this.template, {
      class: "gallary_modal",
    });
  }
}
