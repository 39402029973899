<!-- <app-top-header class="top-bar-container"   [hideLogo]="true" [handleScroll]="true"></app-top-header> -->
<app-top-header class="top-bar-container" [bgStyle]="'solid'"></app-top-header>

<div style="max-width: 100%; width: 100%; overflow: hidden !important">
  <div
    class="page-btn font-extraBold"
    *ngIf="cart?.cartItems?.length"
    routerLink="/cart"
  >
    <a
      href="javascript:{}"
      class="btn btn-primary d-flex justify-content-between align-items-center"
    >
      <div>{{ cart?.$totalItemsQty }}</div>
      <div class="flex-grow-1 text-center">{{ "Review Cart" | translate }}</div>
      <div>
        {{ cart?.$subTotal | iknCurrency }}
        {{ cart?.$truck?.defaultCountry?.currencyCode | iknLocalized }}
      </div>
    </a>
  </div>
</div>

<div class="social" *ngIf="iosLink || androidLink">
  <div *ngIf="iosLink" class="text-align">
    <a [href]="iosLink" target="_blank"
      ><img
        src="../../assets/images/app-store-badge.png"
        style="width: 250px; padding-left: 8px"
    /></a>
  </div>
  <div *ngIf="androidLink" class="text-align">
    <a [href]="androidLink" target="_blank"
      ><img
        src="../../assets/images/google-play-badge.png"
        style="width: 300px"
    /></a>
  </div>
</div>

<app-extra-category-alert class="pull-heading-right"></app-extra-category-alert>
<app-menu-alert class="pull-heading-right"></app-menu-alert>
<div class="page-wrapper">
  <section
    class="home-slide slide_sec position-relative"
    *ngIf="!isLoading"
    [@fadeInOnEnter]
  >
    <div class="container slide-blog p-0">
      <div *ngIf="truckPics.length" class="row no-gutters">
        <div class="col-12">
          <owl-carousel-o [options]="topSlidersOptions">
            <ng-template carouselSlide *ngFor="let pic of truckPics">
              <!-- <div
                [style.background-image]="
                  'url(' + pic.pic || '/assets/images/logo.png' + ')'
                "
                style="min-height: 250px; width: 100%"
                [style.backgroundSize]="'contain'"
                [style.backgroundRepeat]="'no-repeat'"
                [style.backgroundPosition]="'center center'"
              ></div> -->

              <img
                [src]="pic.pic || '/assets/images/logo.png'"
                alt=""
                (click)="navigateToUrl(pic)"
                class="d-block w-100 carousel-item"
              />
            </ng-template>
          </owl-carousel-o>

          <!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <ng-container *ngFor="let pic of truckPics; let i = index">
                <div class="carousel-item" [ngClass]="{ active: i == 0 }">
                  <img [src]="pic.pic || '/assets/images/logo.png'" alt="" (click)="navigateToUrl(pic)"
                    class="d-block w-100" />
                </div>
              </ng-container>
            </div>
          </div> -->
        </div>
      </div>
      <!-- row -->
    </div>
  </section>
  <!-- slide_sec -->

  <section class="product-sec py-3" *ngIf="!isLoading" [@fadeInOnEnter]>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="product-blog d-flex align-items-center pointer"
          [routerLink]="['/info']"
        >
          <ng-container *ngIf="truck?.truck?.logoPic" class="med-logo">
            <img class="product-blog__image" [src]="truck?.truck?.logoPic" />
          </ng-container>
          <div class="product-blog__details px-2">
            <h5>{{ truck?.truck?.name | iknLocalized }}</h5>
            <ul class="d-flex">
              <li>
                <img src="assets/styles/imgs/credit-card.png" alt="" />
              </li>
              <li>
                <img src="assets/styles/imgs/cash.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
        <div [routerLink]="['/info']" class="more-info pointer">
          <!-- <i class="icon-exclamination"></i> -->
          <img
            src="../../assets/images/Icon feather-info.png"
            alt="Order Submitted Successfully"
          />
        </div>
      </div>
    </div>
  </section>

  <section
    class="categories-labels-sec py-3"
    *ngIf="!isLoading"
    [@fadeInOnEnter]
  >
    <div class="container container-padding-5">
      <div class="d-flex">
        <ul class="categories-labels">
          <li class="align-middle" *ngFor="let srv of services; let i = index">
            <a
              [ngClass]="{
                'active-cat':
                  ('srv_' + cart.serviceType_ex | translate) ==
                  ('srv_' + srv | translate)
              }"
              (click)="setService(srv)"
              class="btn btn-outline-secondary px-1"
            >
              <svg
                *ngIf="i == 0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="#707070"
                  d="M112 0C85.5 0 64 21.5 64 48V96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 272c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 240c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 208c8.8 0 16 7.2 16 16s-7.2 16-16 16H64V416c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H112zM544 237.3V256H416V160h50.7L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"
                />
              </svg>

              <svg
                *ngIf="i == 1"
                xmlns="http://www.w3.org/2000/svg"
                width="20.149"
                viewBox="0 0 20.149 20.154"
              >
                <g
                  id="Icon_ionic-ios-timer"
                  data-name="Icon ionic-ios-timer"
                  transform="translate(-3.938 -3.938)"
                >
                  <path
                    id="Path_2306"
                    data-name="Path 2306"
                    d="M14.015,24.092A10.077,10.077,0,0,1,7.021,6.759.809.809,0,0,1,8.145,7.923,8.457,8.457,0,1,0,14.821,5.6V8.749a.811.811,0,1,1-1.622,0v-4a.811.811,0,0,1,.811-.811,10.077,10.077,0,0,1,.005,20.154Z"
                    transform="translate(0)"
                    fill="#707070"
                  />
                  <path
                    fill="#707070"
                    id="Path_2307"
                    data-name="Path 2307"
                    d="M12.052,11.346l5.013,3.6A1.517,1.517,0,1,1,15.3,17.418a1.465,1.465,0,0,1-.353-.353l-3.6-5.013a.505.505,0,0,1,.705-.705Z"
                    transform="translate(-2.073 -2.073)"
                    fill="#707070"
                  />
                </g>
              </svg>

              <!-- <svg
                *ngIf="i == 1"
                xmlns="http://www.w3.org/2000/svg"
                width="20.149"
                height="20.154"
                viewBox="0 0 20.149 20.154"
              >
                <g
                  id="Icon_ionic-ios-timer"
                  data-name="Icon ionic-ios-timer"
                  transform="translate(-3.938 -3.938)"
                >
                  <path
                    fill="#707070"
                    id="Path_2306"
                    data-name="Path 2306"
                    d="M14.015,24.092A10.077,10.077,0,0,1,7.021,6.759.809.809,0,0,1,8.145,7.923,8.457,8.457,0,1,0,14.821,5.6V8.749a.811.811,0,1,1-1.622,0v-4a.811.811,0,0,1,.811-.811,10.077,10.077,0,0,1,.005,20.154Z"
                    transform="translate(0)"
                    fill="#707070"
                  />
                  <path
                    fill="#707070"
                    id="Path_2307"
                    data-name="Path 2307"
                    d="M12.052,11.346l5.013,3.6A1.517,1.517,0,1,1,15.3,17.418a1.465,1.465,0,0,1-.353-.353l-3.6-5.013a.505.505,0,0,1,.705-.705Z"
                    transform="translate(-2.073 -2.073)"
                    fill="#707070"
                  />
                </g>
              </svg> -->

              <!-- <svg
                
                *ngIf="i == 1"
                
                xmlns="http://www.w3.org/2000/svg"
                width="24.647"
                height="24.653"
                viewBox="0 0 24.647 24.653"
              >
                <g
                  id="Icon_ionic-ios-timer"
                  data-name="Icon ionic-ios-timer"
                  transform="translate(-3.938 -3.938)"
                >
                  <path
                    id="Path_2306"
                    data-name="Path 2306"
                    d="M16.264,28.59a12.327,12.327,0,0,1-8.555-21.2A.989.989,0,1,1,9.084,8.813,10.345,10.345,0,1,0,17.25,5.965V9.823a.992.992,0,1,1-1.985,0V4.93a.992.992,0,0,1,.992-.992,12.326,12.326,0,0,1,.006,24.653Z"
                    transform="translate(0)"
                    fill="#707070"
                  />
                  <path
                    id="Path_2307"
                    data-name="Path 2307"
                    d="M12.23,11.367l6.132,4.407a1.856,1.856,0,1,1-2.157,3.02,1.792,1.792,0,0,1-.431-.431L11.367,12.23a.618.618,0,0,1,.863-.863Z"
                    transform="translate(-0.903 -0.903)"
                    fill="#707070"
                  />
                </g>
              </svg> -->

              <svg
                *ngIf="i == 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#707070"
                  d="M320 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM125.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8 .1 5.6 .3L137.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L238 299l30.9-82.4 5.1 12.3C289 264.7 323.9 288 362.7 288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H362.7c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15c-11.1-3.4-22.7-5.2-34.4-5.2c-31 0-60.8 12.3-82.7 34.3L57.4 153.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM91.2 352H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5L157 361.6l-9.5-6c-17.5-10.9-30.5-26.8-37.9-44.9L91.2 352z"
                />
              </svg>

              {{ "srv_" + srv | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!-- .categories-labels-sec -->
  <section
    class="delivering-sec border-bottom-none"
    *ngIf="!isLoading"
    [@fadeInOnEnter]
  >
    <div class="container">
      <p class="m-0">
        <span
          class="font-12 font-bold color-black"
          *ngIf="
            (isShipping && !cart.orderInfo.pickupAddress.countryId) ||
            (!isShipping &&
              (!cart.orderInfo.pickupAddress.areaId || !cart.serviceType_ex) &&
              cart.orderInfo.serviceType != 'pickup')
          "
          >{{ "Area" | translate }}</span
        >

        <span
          class="font-12 font-bold color-black"
          *ngIf="
            !(
              (isShipping && !cart.orderInfo.pickupAddress.countryId) ||
              (!isShipping &&
                (!cart.orderInfo.pickupAddress.areaId ||
                  !cart.serviceType_ex) &&
                cart.orderInfo.serviceType != 'pickup')
            )
          "
          >{{ "Deliver to" | translate }}</span
        >
      </p>
    </div>
    <div class="container pb-2 categories-labels-sec">
      <ul>
        <li>
          <div class="title-deliver">
            <i class="icon-car active-color i-small"></i>
            <p class="m-0 pt-0 no-y-padding">
              <!-- <span
                *ngIf="
                  !isShipping &&
                  cart.orderInfo.pickupAddress.areaId &&
                  cart.serviceType_ex
                "
              >
                <span *ngIf="cart.orderInfo.serviceType == 'pickup'">
                  <span *ngIf="cart.$branch">
                    - {{ cart.$branch?.name | iknLocalized }}</span
                  >
                  <span *ngIf="!cart.$branch">
                    - {{ "Main Branch" | translate }}</span
                  >
                </span>
                <span *ngIf="cart.orderInfo.serviceType != 'pickup'">
                  {{
                    cart.orderInfo.pickupAddress.areaName | iknLocalized
                  }}</span
                >
              </span>
              <span
                *ngIf="
                  isShipping &&
                  cart.orderInfo.pickupAddress.countryId &&
                  cart.serviceType_ex
                "
              >
                <span class="px-2"
                  >{{ cart.orderInfo.pickupAddress.countryName | iknLocalized }}
                  -
                  {{
                    cart.orderInfo.pickupAddress.governateName | iknLocalized
                  }}</span
                >
              </span> -->

              <!-- <span
                *ngIf="
                  (isShipping && !cart.orderInfo.pickupAddress.countryId) ||
                  (!isShipping &&
                    (!cart.orderInfo.pickupAddress.areaId ||
                      !cart.serviceType_ex) &&
                    cart.orderInfo.serviceType != 'pickup')
                "
                >{{ "Choose Service - Area" | translate }}
              </span> -->

              <span *ngIf="!isShipping && cart.serviceType_ex">
                <span *ngIf="cart.orderInfo.serviceType == 'pickup'">
                  <span *ngIf="cart.$branch" class="color-black font-sm-size">
                    - {{ cart.$branch?.name | iknLocalized }}</span
                  >
                  <span class="color-black font-sm-size" *ngIf="!cart.$branch">
                    - {{ "Main Branch" | translate }}</span
                  >
                </span>
                <span
                  class="color-black font-sm-size"
                  *ngIf="cart.orderInfo.serviceType != 'pickup'"
                >
                  -
                  {{
                    cart.orderInfo.pickupAddress.areaName | iknLocalized
                  }}</span
                >
              </span>
              <span
                *ngIf="
                  isShipping &&
                  cart.orderInfo.pickupAddress.countryId &&
                  cart.serviceType_ex
                "
              >
                <span class="px-2 color-black font-sm-size"
                  >{{ cart.orderInfo.pickupAddress.countryName | iknLocalized }}
                  -
                  {{
                    cart.orderInfo.pickupAddress.governateName | iknLocalized
                  }}</span
                >
              </span>
            </p>
          </div>
          <div class="details-deliver">
            <a [routerLink]="['/service']" class="edit">{{
              "Edit" | translate
            }}</a>
          </div>
        </li>
      </ul>
    </div>

    <div class="container">
      <p class="m-0">
        <span class="font-12 font-bold color-black">
          {{ "Delivery time" | translate }}
        </span>

        <!-- <span
          class="font-12 font-bold color-black"
          *ngIf="!cart?.orderInfo.pickupTime"
        >
          {{ "Choose Time - Date" | translate }}
        </span>
        <span
          class="font-12 font-bold color-black"
          *ngIf="cart?.orderInfo.pickupTime"
        >
          {{ "Deliver Now" | translate }}
        </span> -->
      </p>
    </div>
    <div class="container pb-2 categories-labels-sec">
      <ul>
        <li>
          <div class="title-deliver">
            <i class="icon-clock active-color i-sm-small"></i>
            <p class="m-0 no-y-padding">
              <span
                class="color-black font-sm-size"
                *ngIf="cart?.orderInfo.pickupTime"
              >
                {{ cart?.orderInfo.pickupTime | iknDate : "day" }}
                {{ cart?.orderInfo.pickupTime | iknDate : "dm" }}
                {{ cart?.orderInfo.pickupTime | iknDate : "tf"
                }}<span
                  class="color-black font-sm-size"
                  *ngIf="cart?.orderInfo.pickupTimeTo"
                >
                  -
                  {{ cart?.orderInfo.pickupTimeTo | iknDate : "tf" }}</span
                >
              </span>
            </p>
          </div>
          <div class="details-deliver d-block">
            <a [routerLink]="['/time-date']" class="edit">{{
              "Edit" | translate
            }}</a>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="container">
      <div>
        <ul>
          <li>
            <div class="title-deliver">
              <i class="icon-car"></i>
              <p class="m-0">
                <span
                  *ngIf="
                    !isShipping &&
                    cart.orderInfo.pickupAddress.areaId &&
                    cart.serviceType_ex
                  "
                >
                  <span *ngIf="cart.orderInfo.serviceType == 'pickup'">
                    <span *ngIf="cart.$branch">
                      - {{ cart.$branch?.name | iknLocalized }}</span
                    >
                    <span *ngIf="!cart.$branch">
                      - {{ "Main Branch" | translate }}</span
                    >
                  </span>
                  <span *ngIf="cart.orderInfo.serviceType != 'pickup'">
                    {{
                      cart.orderInfo.pickupAddress.areaName | iknLocalized
                    }}</span
                  >
                </span>
                <span
                  *ngIf="
                    isShipping &&
                    cart.orderInfo.pickupAddress.countryId &&
                    cart.serviceType_ex
                  "
                >
                  <span class="px-2"
                    >{{
                      cart.orderInfo.pickupAddress.countryName | iknLocalized
                    }}
                    -
                    {{
                      cart.orderInfo.pickupAddress.governateName | iknLocalized
                    }}</span
                  >
                </span>
              </p>
            </div>
            <div class="details-deliver">
              <a [routerLink]="['/service']" class="edit">{{
                "Edit" | translate
              }}</a>
            </div>
          </li>
          <li>
            <div class="title-deliver">
              <i class="icon-clock"></i>
              <p class="m-0">
                <span *ngIf="!cart?.orderInfo.pickupTime">
                  {{ "Choose Time - Date" | translate }}
                </span>
                <span *ngIf="cart?.orderInfo.pickupTime">
                  {{ "Deliver Now" | translate }}
                </span>
              </p>
            </div>
            <div class="details-deliver">
              <h6 class="m-0">
                <span *ngIf="cart?.orderInfo.pickupTime">
                  {{ cart?.orderInfo.pickupTime | iknDate : "day" }}
                  {{ cart?.orderInfo.pickupTime | iknDate : "dm" }}
                  {{ cart?.orderInfo.pickupTime | iknDate : "tf"
                  }}<span *ngIf="cart?.orderInfo.pickupTimeTo">
                    -
                    {{ cart?.orderInfo.pickupTimeTo | iknDate : "tf" }}</span
                  >
                </span>
              </h6>
              <a
                *ngIf="
                  cart.serviceType_ex &&
                  cart.serviceType_ex != 'soonest_delivery' &&
                  cart.serviceType_ex != 'pickup'
                "
                [routerLink]="['/time-date']"
                href=""
                class="edit"
                >{{ "Edit" | translate }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div> -->
  </section>

  <section class="search-sec py-3" *ngIf="!isLoading" [@fadeInOnEnter]>
    <div class="container">
      <div class="form-group m-0">
        <div class="input-group input-group-abs">
            <div class="input-group-prepend">
            <i class="icon-search"></i>
          </div> 

          <input
            placeholder="{{
              (availableItems ? 'SEARCH_PLACE_HOLDER' : 'Search')
                | translate : { count: availableItems }
            }}"
            [(ngModel)]="searchText"
            class="form-control px-5 w-100 border-0"
          />
        </div>

        <div
          class="menu-block w-100"
          [@fadeInOnEnter]
          [@fadeOutOnLeave]
          *ngIf="
            searchText && searchText?.length && !isLoadingMenu && !isLoading
          "
        >
          <div class="mt-5 categories-sec">
            <div class="cards-sec list-mode">
              <div
                class="col-card"
                *ngFor="
                  let item of menu?.allItems | filter : searchText;
                  let i = index
                "
              >
                <app-menu-item-card
                  [item]="item"
                  [extraChargeAlert]="extraCatAlertSelector"
                ></app-menu-item-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="categories-sec">
    <div
      *ngIf="showCategoryMode == categoryModes.ALL && !isLoading"
      class="container"
    >
      <div class="d-flex justify-content-end">
        <div>
          <ul class="d-flex">
            <li>
              <button
                [ngClass]="{
                  'secondary-color': categoryMode == categoryModes.GRID
                }"
                (click)="showCategoryCols()"
                class="grid-cols"
              >
                <i class="icon-blocks"></i>
              </button>
            </li>
            <li>
              <button
                [ngClass]="{
                  'secondary-color': categoryMode == categoryModes.LIST
                }"
                (click)="showCategoryRow()"
                class="grid-row secondary-color"
              >
                <i class="icon-list"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Categorries scroll  -->

    <div class="container subHeader" *ngIf="listmode && !isLoading">
      <div class="d-flex justify-content-start mb-2">
        <ul class="scroller">
          <li
            data-toggle="collapse"
            [attr.data-target]="'#id-top-category'"
            [ngClass]="{ 'active-category': selectedCateory == '' }"
          >
            <svg
              (click)="showTopCategory = true"
              style="width: 20px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                class="switch-color"
                d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
              />
            </svg>
          </li>
        </ul>
        <ul #widgetsContent class="scroller">
          <ng-container *ngFor="let cat of menu?.categories; let i = index">
            <li
              [id]="'catid' + cat.categoryInfo.id + 'Head'"
              [ngClass]="{
                'active-category':
                  selectedCateory == 'catid' + cat.categoryInfo.id
              }"
              (click)="toTopCat(cat.categoryInfo.id)"
            >
              <span>{{ cat.categoryInfo.title | iknLocalized }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="mt-3 px-2">
      <div class="cards-sec" [ngClass]="{ row: listmode != true }">
        <div
          [ngClass]="{
            'px-1': listmode != true,
            'px-0 border-bottom-1': listmode == true
          }"
          *ngFor="
            let cat of menu?.categories | category : searchText;
            let i = index
          "
          class="col-6 col-card"
          [id]="'catid' + cat.categoryInfo.id"
        >
          <app-base-category-card
            [showItems]="listmode"
            [lastCategory]="i == menu?.categories?.length - 1"
            [category]="cat"
          ></app-base-category-card>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Categorries hidden menu  -->
<!-- <section class="section-dropdown   position-relative">
 
</section> -->

<section class="section-dropdown position-relative">
  <div
    class="menu-mobile"
    [ngClass]="showTopCategory ? 'menu-mobile-show' : ''"
  >
    <div
      class="menu-mobile-overlay"
      (click)="showTopCategory = !showTopCategory"
    ></div>
    <div class="menu-mobile-content scroll scroll-wight-sidemenu">
      <div class="  ">
        <ul [ngClass]="{ show: showTopCategory }">
          <li>
            <div
              class="d-flex justify-content-between align-items-center pt-5 pb-3 ps-4 px-4"
            >
              <div class=" ">
                <h5>
                  {{ "Menu List" | translate }}
                </h5>
              </div>
              <div class="icon-close" (click)="showTopCategory = false"></div>
            </div>
          </li>

          <ng-container *ngFor="let cat of menu?.categories; let i = index">
            <li
              (click)="toTopCat(cat.categoryInfo.id); showTopCategory = false"
            >
              <div
                class="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 px-4"
              >
                <div class=" ">
                  <span class="  ">
                    {{ cat.categoryInfo.title | iknLocalized }}</span
                  >
                </div>
                <div class=" ">
                  <h6>{{ cat?.items?.length }} {{ "items" | translate }}</h6>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</section>

<div
  iknLoader
  [isActive]="isLoading || isLoadingMenu"
  class="loader-container mt-5 pt-5 w-100 text-center"
></div>
