<div class="top-bar" [ngClass]="{ solid: bgStyle == 'solid' }">
  <div class="d-flex flex-column">
    <div
      class="px-1 d-flex align-items-center justify-content-between top-bar-header"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <a *ngIf="!isServiceScreen" class="link-icon">
            <i
              (click)="expanded = !expanded"
              class="fa fa-bars collapse-sub-menu"
            ></i>
          </a>
          <a *ngIf="isServiceScreen" (click)="goBack()" class="link-icon">
            <i class="px-1 far fa-angle-left fa-lg"></i>
            <i class="px-1"></i>
          </a>

          <a
            *ngIf="info?.supportPhone"
            href="https://api.whatsapp.com/send?phone=965{{
              info?.supportPhone
            }}"
            class="link-icon px-2"
            target="_blank"
          >
            <i class="fa fa-whatsapp"></i>
          </a>
        </div>
      </div>
      <img
        routerLink=".."
        *ngIf="!hideLogo"
        class="logo-small cursor-pointer"
        [src]="info?.logoPic"
        style="
          max-height: 45px;
          min-width: 45px;
          min-height: 45px;
          max-width: 45px;
          border-radius: 50%;
        "
      />
      <div class="d-flex align-items-center justify-content-center">
        <!-- <a  class=" link-icon">
          <i class="icon-search fa-2x font-weight-bold"></i>
           
          </a> -->
        <div class="px-2">
          <app-cart-icon></app-cart-icon>
        </div>
        <a class="lang-switcher link-icon" (click)="toggleLang()"
          ><i class="im im-ayin t-large"></i
        ></a>
      </div>
    </div>
    <!-- <div class="container slide-header py-3">
      <div class="row justify-content-between align-items-center">
          <div class="col-auto">
              <div class="side-menu">
                  <i class="icon-bar fa-lg"></i>
              </div>
          </div>
          <div class="col-auto">
              <div class="icons-blog d-flex">
                  <i class="icon-search fa-lg"></i>
                  <i class="icon-Bag fa-lg"></i>
           
              </div>
          </div>
      </div>
  </div> -->
    <div *ngIf="expanded" class="top-menu color-white">
      <ul class="p-2">
        <!-- <li class="my-2" *ngIf="!isLoggedIn()"><a routerLink='/register' class="color-white close-menu">{{'Register' | translate}}</a></li> -->
        <li class="my-2" *ngIf="!isLoggedIn()">
          <a [routerLink]="['/login']" class="color-white close-menu">{{
            "Login / Register" | translate
          }}</a>
        </li>
        <!-- <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/profile' class="color-white close-menu">{{'Account Info' | translate}}</a></li> -->
        <li class="my-2" *ngIf="isLoggedIn()">
          <a routerLink="/my-orders" class="color-white close-menu">{{
            "My Orders" | translate
          }}</a>
        </li>
        <li class="my-2">
          <a routerLink="/track-order" class="color-white close-menu">{{
            "Track Your Order" | translate
          }}</a>
        </li>
        <li class="my-2">
          <a routerLink="/info" class="color-white close-menu">{{
            "Our Branches" | translate
          }}</a>
        </li>
        <li class="my-2" *ngIf="isLoggedIn()">
          <a class="color-white close-menu cursor-pointer" (click)="logout()">{{
            "Sign Out" | translate
          }}</a>
        </li>
        <li class="my-2" *ngIf="isLoggedIn()">
          <a routerLink="/my-addresses" class="color-white close-menu">{{
            "Delivery Addresses" | translate
          }}</a>
        </li>
      </ul>
    </div>
    <div
      *ngIf="isCategoryItemsScreen && screenTitle"
      class="py-2 container d-flex align-items-center color-primary px-4 border-bottom-none"
    >
      <div class="pt-2 cursor-pointer" (click)="goBack()">
        <i class="px-1 far fa-angle-left fa-2x"></i>
        <i class="px-1"></i>
      </div>
      <h6
        [ngClass]="{ 'category-menu-items-title ': isCategoryItemsScreen }"
        class="pt-2 f-boldes t-large font-weight-bold px-3"
      >
        {{ screenTitle | translate }}
      </h6>
    </div>
    <div
      class="py-2 container d-flex align-items-center border-bottom color-primary"
      [ngClass]="{
        'border-bottom-none': isServiceScreen
      }"
      *ngIf="screenTitle && !isCategoryItemsScreen"
    >
      <div
        *ngIf="!isServiceScreen"
        class="pt-2 cursor-pointer"
        (click)="goBack()"
      >
        <i class="px-1 far fa-angle-left fa-2x"></i>
        <i class="px-1"></i>
      </div>
      <h6 class="pt-2 f-boldes t-large font-weight-bold px-3">
        {{ screenTitle | translate }}
      </h6>
    </div>
  </div>
</div>
